import { ToolOutlined } from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import { Button, Card, Col, Row, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { centers } from '../data';
import { fakeRelease } from '../lib/fakeNotif';

export default function QuickActions() {
	const errorList = useMemo(() => {
		const randomizeActionableError = () => {
			return faker.helpers.arrayElement([
				{
					title: 'Intermission time not assigned',
					description: fakeRelease(),
					action: (
						<Tooltip title='Assign'>
							<Button size='small' shape='circle'>
								<ToolOutlined />
							</Button>
						</Tooltip>
					),

					dueDate: dayjs(faker.date.soon({ days: 6 })),
				},
				{
					title: 'Credit offset not assigned',
					description: fakeRelease(),
					action: (
						<Tooltip title='Assign'>
							<Button size='small' shape='circle'>
								<ToolOutlined />
							</Button>
						</Tooltip>
					),

					dueDate: dayjs(faker.date.soon({ days: 6 })),
				},
				{
					title: 'Not enough disk space available',
					description: `${faker.helpers.arrayElement(
						centers
					)} ${faker.number.int({ min: 1, max: 9 })}`,
					action: (
						<Tooltip title='Assign'>
							<Button size='small' shape='circle'>
								<ToolOutlined />
							</Button>
						</Tooltip>
					),

					dueDate: dayjs(faker.date.soon({ days: 6 })),
				},
				{
					title: 'Release type not assigned',
					description: fakeRelease(),
					action: (
						<Tooltip title='Assign'>
							<Button size='small' shape='circle'>
								<ToolOutlined />
							</Button>
						</Tooltip>
					),

					dueDate: dayjs(faker.date.soon({ days: 6 })),
				},
			]);
		};

		return faker.helpers
			.multiple(randomizeActionableError, { count: 50 })
			.sort((a, b) => a.dueDate.diff(b.dueDate));
	}, []);
	return (
		<div>
			<Typography.Title level={5}>Quick actions</Typography.Title>
			<Row
				gutter={[8, 8]}
				style={{ marginTop: 16, flexWrap: 'nowrap', overflowX: 'auto' }}
			>
				{errorList.map((error, index) => (
					<Col key={index} span={5}>
						<Card
							type='inner'
							size='small'
							title={error.title}
							extra={error.action}
						>
							<Typography.Text italic type='secondary'>
								due {dayjs().to(error.dueDate)}
							</Typography.Text>
							<p>{error.description}</p>
						</Card>
					</Col>
				))}
			</Row>
		</div>
	);
}
