export const centers = [
	'Basel Capitol',
	'Bern Cinedome',
	'Biel Cinedome',
	'Luzern Capitol',
	'Luzern Maxx',
	'St. Gallen Cinedome',
	'St. Gallen Scala',
	'Winterthur Maxx',
	'Zürich Abaton',
	'Zürich Capitol',
	'Zürich Corso',
	'Zürich Metropol',
	'blue Cinema Chur',
];
