import {
	CheckCircleOutlined,
	CheckCircleTwoTone,
	InfoCircleOutlined,
	LeftOutlined,
	LockTwoTone,
	MenuOutlined,
	PlusOutlined,
	RightOutlined,
	WarningOutlined,
	WarningTwoTone,
} from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import {
	Badge,
	Breadcrumb,
	Button,
	Card,
	Col,
	Collapse,
	Descriptions,
	Dropdown,
	Layout,
	Menu,
	MenuProps,
	Modal,
	Popover,
	Row,
	Space,
	Statistic,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekofYear from 'dayjs/plugin/weekOfYear';
import { useMemo, useState } from 'react';
import CountUp from 'react-countup';
import FilmOverview from './components/FilmOverview';
import ModalSkeleton from './components/ModalSkeleton';
import QuickActions from './components/QuickActions';
import { centers } from './data';
import createFilm, { distributors } from './lib/createFilm';
import FakeNotif, { fakeDCP, fakeRelease } from './lib/fakeNotif';
import { getGreetingTime } from './lib/getGreetingTime';
const { Header, Content, Sider } = Layout;

export const filmListRaw = [
	'The Shawshank Redemption',
	'The Godfather',
	'Pulp Fiction',
	'The Dark Knight',
	'Fight Club',
	'Goodfellas',
	'Inception',
	'The Matrix',
	"Schindler's List",
	'The Lord of the Rings: The Fellowship of the Ring',
	'Forrest Gump',
	'The Avengers',
	'Interstellar',
	'The Silence of the Lambs',
	'The Lion King',
	'Star Wars: Episode IV - A New Hope',
	'Gladiator',
	'Jurassic Park',
	'The Departed',
	'Titanic',
];
dayjs.extend(weekofYear);
dayjs.extend(relativeTime);

const formatter = (value: any) => <CountUp end={value} separator=',' />;

const releaseMenuItems: MenuProps['items'] = [
	{
		key: '1',
		label: 'Assign CTID',
	},
	{
		key: '2',
		label: 'Add Intermission Time',
	},
	{
		key: '3',
		label: 'Add Credit Offset',
	},
];

function App() {
	const [date, setDate] = useState(dayjs(new Date()).day(4).add(1, 'week'));
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalData, setModalData] = useState({
		title: 'balsh',
		children: <>This is the content</>,
	});

	const [user, setUser] = useState({ name: faker.person.firstName() });

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const data = useMemo(() => {
		return faker.helpers
			.arrayElements(filmListRaw, { min: 5, max: 9 })
			.map((film) => {
				return createFilm({ filmTitle: film });
			});
	}, []);

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Modal
				title={modalData.title}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				children={modalData.children}
				destroyOnClose
			/>
			<Header
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					position: 'sticky',
					top: 0,
					zIndex: 1,
				}}
			>
				<div style={{ flexGrow: 1 }}></div>
				<div style={{ flexGrow: 0 }}>
					<FakeNotif />
				</div>
			</Header>
			<Layout>
				<Sider width={200}>
					<Menu
						mode='inline'
						defaultSelectedKeys={['1']}
						defaultOpenKeys={['sub1']}
						style={{ height: '100%', borderRight: 0 }}
					/>
				</Sider>
				<Layout style={{ padding: '0 24px 24px', height: '100%' }}>
					<Breadcrumb style={{ margin: '16px 0' }}>
						<Breadcrumb.Item>Home</Breadcrumb.Item>
						<Breadcrumb.Item>Dashboard</Breadcrumb.Item>
						<Breadcrumb.Item>FiWe</Breadcrumb.Item>
					</Breadcrumb>
					<Content
						style={{
							padding: 24,
							margin: 0,
						}}
					>
						<section style={{ marginBottom: 24 }}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Button
									type='primary'
									shape='circle'
									onClick={() => setDate(date.subtract(1, 'week'))}
								>
									<LeftOutlined />
								</Button>
								<div style={{ margin: 0, flexGrow: 1, textAlign: 'center' }}>
									<Typography.Title style={{ margin: 0 }} level={1}>
										KW {date.week()}
									</Typography.Title>
									<Typography.Text type='secondary' style={{ margin: 0 }}>
										{date.format('DD.MM.YY')} -{' '}
										{dayjs(date).add(6, 'day').format('DD.MM.YY')}
									</Typography.Text>
								</div>
								<Button
									type='primary'
									shape='circle'
									onClick={() => setDate(date.add(1, 'week'))}
								>
									<RightOutlined />
								</Button>
							</div>
						</section>
						<section style={{ marginBottom: 24 }}>
							<Card>
								<Typography.Title
									level={1}
									style={{ marginTop: 0, fontSize: 62, fontWeight: 400 }}
								>
									{getGreetingTime(dayjs(new Date()))} {user.name},
								</Typography.Title>
								<Typography.Text>
									This week,{' '}
									{
										<Popover
											content={faker.helpers
												.arrayElements(filmListRaw, { min: 4, max: 4 })
												.map((film) => (
													<li>{film}</li>
												))}
										>
											<Typography.Text underline>
												4 new features
											</Typography.Text>
										</Popover>
									}{' '}
									are being released in the selected centers.
									<br />
									Some issues require your immediate attention.
									<br />
									Also,{' '}
									{
										<Popover
											content={['Lucern Maxx 1', 'Lucern Maxx 2'].map(
												(film) => (
													<li>{film}</li>
												)
											)}
										>
											<Typography.Text underline>2 xenon bulb</Typography.Text>
										</Popover>
									}{' '}
									are set to expire.
								</Typography.Text>
								<div>
									<QuickActions />
								</div>
							</Card>
						</section>
						<section>
							<Row gutter={[16, 16]} style={{ marginTop: 16 }}>
								<Col span={6}>
									<Badge.Ribbon text='Click me!'>
										<Card
											bordered={false}
											hoverable
											onClick={() => {
												setIsModalOpen(true);
												setModalData({
													title: 'New Releases this week',
													children: (
														<>
															<Collapse size='small' ghost>
																{faker.helpers
																	.arrayElements(filmListRaw, {
																		min: 4,
																		max: 4,
																	})
																	.map((film) => (
																		<Collapse.Panel
																			header={`${film} • ${faker.helpers.arrayElement(
																				distributors
																			)}`}
																			key={film}
																		>
																			<Collapse size='small' ghost>
																				{faker.helpers
																					.arrayElements(centers, {
																						min: 1,
																						max: 3,
																					})
																					.map((center) => (
																						<Collapse.Panel
																							header={center}
																							key={center}
																						>
																							{faker.helpers
																								.arrayElements(
																									[1, 2, 3, 4, 5, 6],
																									{ min: 1, max: 3 }
																								)
																								.map((server) => (
																									<Tag>
																										{center} {server}
																									</Tag>
																								))}
																						</Collapse.Panel>
																					))}
																			</Collapse>
																		</Collapse.Panel>
																	))}
															</Collapse>
														</>
													),
												});
											}}
										>
											<Statistic
												title='New releases this week'
												value={4}
												valueStyle={{ color: '#3f8600' }}
												prefix={<PlusOutlined />}
												formatter={formatter}
											/>
										</Card>
									</Badge.Ribbon>
								</Col>
								<Col span={6} />
								<Col span={6} />
								<Col span={6} />
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'CTIDs not assigned',
												children: <ModalSkeleton />,
											});
										}}
									>
										<Statistic
											title='CTIDs not assigned'
											value={1}
											valueStyle={{ color: 'red' }}
											prefix={<WarningOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
										<Typography.Text
											style={{ fontSize: 11, color: 'red' }}
											disabled
											italic
											strong
										>
											due in 1 days
										</Typography.Text>
									</Card>
								</Col>
								<Col span={6}>
									<Badge.Ribbon text='Click me!'>
										<Card
											bordered={false}
											hoverable
											onClick={() => {
												setIsModalOpen(true);
												setModalData({
													title: 'Missing DCP in centers',
													children: (
														<>
															<Collapse
																size='small'
																ghost
																defaultActiveKey={['0', '1', '2', '3']}
															>
																{[
																	fakeDCP(),
																	fakeDCP(),
																	fakeDCP(),
																	fakeDCP(),
																].map((dcp, i) => (
																	<Collapse.Panel header={dcp} key={i}>
																		<Collapse size='small' ghost>
																			{faker.helpers
																				.arrayElements(centers, {
																					min: 1,
																					max: 3,
																				})
																				.map((center) => (
																					<Collapse.Panel
																						header={center}
																						key={center}
																						extra={
																							<Typography.Text
																								italic
																								style={{ color: 'orange' }}
																								strong
																							>{`due ${dayjs().to(
																								dayjs(
																									faker.date.soon({ days: 5 })
																								)
																							)}`}</Typography.Text>
																						}
																					>
																						{faker.helpers
																							.arrayElements(
																								[1, 2, 3, 4, 5, 6],
																								{ min: 1, max: 3 }
																							)
																							.map((server) => (
																								<Tag>
																									{center} {server}
																								</Tag>
																							))}
																					</Collapse.Panel>
																				))}
																		</Collapse>
																	</Collapse.Panel>
																))}
															</Collapse>
														</>
													),
												});
											}}
										>
											<Statistic
												title='Missing DCP in centers'
												value={12}
												valueStyle={{ color: 'red' }}
												prefix={<WarningOutlined />}
												formatter={formatter}
												//suffix='/ 56'
											/>
											<Typography.Text
												style={{ fontSize: 11, color: 'red' }}
												disabled
												italic
												strong
											>
												due in 2 days
											</Typography.Text>
										</Card>
									</Badge.Ribbon>
								</Col>
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'OV/VF Missing',
												children: <ModalSkeleton />,
											});
										}}
										style={{ height: '100%' }}
									>
										<Statistic
											title='OV/VF Missing'
											value={0}
											valueStyle={{ color: 'green' }}
											prefix={<CheckCircleOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'Not enough Disk Space',
												children: <ModalSkeleton />,
											});
										}}
										style={{ height: '100%' }}
									>
										<Statistic
											title='Not enough Disk Space'
											value={3}
											valueStyle={{ color: 'orange' }}
											prefix={<WarningOutlined />}
											suffix='servers'
											formatter={formatter}
										/>
									</Card>
								</Col>

								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'Missing KDM in centers',
												children: <ModalSkeleton />,
											});
										}}
									>
										<Statistic
											title='Missing KDM in centers'
											value={6}
											valueStyle={{ color: 'red' }}
											prefix={<WarningOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
										<Typography.Text
											style={{ fontSize: 11, color: 'red' }}
											disabled
											italic
											strong
										>
											due in 2 days
										</Typography.Text>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'KDM expires during show',
												children: <ModalSkeleton />,
											});
										}}
										style={{ height: '100%' }}
									>
										<Statistic
											title='KDM expires during show'
											value={0}
											valueStyle={{ color: 'green' }}
											prefix={<CheckCircleOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'Credit offset missing',
												children: <ModalSkeleton />,
											});
										}}
										style={{ height: '100%' }}
									>
										<Statistic
											title='Credit offset missing'
											value={0}
											valueStyle={{ color: 'green' }}
											prefix={<CheckCircleOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
									</Card>
								</Col>
								<Col span={6}>
									<Card
										bordered={false}
										hoverable
										onClick={() => {
											setIsModalOpen(true);
											setModalData({
												title: 'Intermission Time missing',
												children: (
													<>
														<Collapse
															size='small'
															ghost
															defaultActiveKey={['0', '1', '2', '3']}
														>
															{[
																fakeRelease(),
																fakeRelease(),
																fakeRelease(),
																fakeRelease(),
															].map((dcp, i) => (
																<Collapse.Panel header={dcp} key={i}>
																	<Collapse size='small' ghost>
																		{faker.helpers
																			.arrayElements(centers, {
																				min: 1,
																				max: 3,
																			})
																			.map((center) => (
																				<Collapse.Panel
																					header={center}
																					key={center}
																					extra={
																						<>
																							<Tooltip title='KDM available'>
																								<Button
																									type='text'
																									icon={
																										<LockTwoTone
																											twoToneColor={'green'}
																											shape='circle'
																										/>
																									}
																								/>
																							</Tooltip>
																							<Typography.Text
																								italic
																								style={{ color: 'orange' }}
																								strong
																							>{`due ${dayjs().to(
																								dayjs(
																									faker.date.soon({ days: 5 })
																								)
																							)}`}</Typography.Text>
																						</>
																					}
																				>
																					{faker.helpers
																						.arrayElements([1, 2, 3, 4, 5, 6], {
																							min: 1,
																							max: 3,
																						})
																						.map((server) => (
																							<Tag>
																								{center} {server}
																							</Tag>
																						))}
																				</Collapse.Panel>
																			))}
																	</Collapse>
																</Collapse.Panel>
															))}
														</Collapse>
													</>
												),
											});
										}}
										style={{ height: '100%' }}
									>
										<Statistic
											title='Intermission Time missing'
											value={2}
											valueStyle={{ color: 'orange' }}
											prefix={<InfoCircleOutlined />}
											formatter={formatter}
											//suffix='/ 56'
										/>
										<Typography.Text
											style={{ fontSize: 11, color: 'orange' }}
											disabled
											italic
											strong
										>
											due in 4 days
										</Typography.Text>
									</Card>
								</Col>
							</Row>
						</section>
						<section style={{ marginTop: 16 }}>
							<Card type='inner'>
								<Descriptions layout='horizontal' column={4}>
									<Descriptions.Item label='DCP Ingest'>
										<Badge status='processing' text='6 in progress' />
									</Descriptions.Item>
									<Descriptions.Item label='KDM ingest'>
										<Badge status='processing' text='2 planned' />
									</Descriptions.Item>
									<Descriptions.Item label='Placeholder'>
										<Badge status='error' text='2 missing' />
									</Descriptions.Item>
									<Descriptions.Item label='Show template'>
										<Badge status='success' text='0 missing' />
									</Descriptions.Item>
									<Descriptions.Item label='Preshow content in center'>
										<Badge status='success' text='0 missing' />
									</Descriptions.Item>
									<Descriptions.Item label='Preshow content in server'>
										<Badge status='success' text='0 missing' />
									</Descriptions.Item>
									<Descriptions.Item label='Cashdesk API'>
										<Badge status='success' text='OK' />
									</Descriptions.Item>
								</Descriptions>
							</Card>
						</section>
						<section style={{ marginTop: 24 }}>
							<Typography.Title level={3}>
								Film list for KW {date.week()}
							</Typography.Title>
							<Card>
								<Collapse defaultActiveKey={['0']} accordion ghost>
									{data.map((film, i) => {
										return (
											<Collapse.Panel
												header={
													<div
														style={{ display: 'flex', alignItems: 'center' }}
													>
														<Typography.Title
															level={5}
															style={{ flexGrow: 1, margin: 0 }}
														>
															{film.filmTitle}
															{' • '}
															<span style={{ color: 'grey' }}>
																{film.distributor}{' '}
															</span>
														</Typography.Title>
														<div style={{ flexGrow: 0 }}>
															<Space>
																<Tag
																	color={
																		film.week === 1 ? 'processing' : 'default'
																	}
																>
																	week {film.week}
																</Tag>
																<Tag>{film.releases.length} releases</Tag>
																{/* 																<Tag
																	icon={<ExclamationCircleOutlined />}
																	color={
																		getDcpsInCenter({ film: film }) ===
																		getTotalDcps({ film: film })
																			? 'success'
																			: 'warning'
																	}
																>
																	{getDcpsInCenter({ film: film })}/
																	{getTotalDcps({ film: film })} DCPs in center
																</Tag>
 */}
															</Space>
														</div>
													</div>
												}
												key={i}
												extra={
													!film.error ? (
														<Space>
															<CheckCircleTwoTone twoToneColor={'green'} />
														</Space>
													) : (
														<Space>
															<Tooltip
																title={
																	<>
																		{faker.helpers
																			.arrayElements(
																				[
																					'Missing DCP (center)',
																					'Missing KDM (center)',
																					'OV/VF Missing',
																					'Intermission Time missing',
																					'Credit offset missing',
																				],
																				{ min: 1, max: 3 }
																			)
																			.map((notif) => (
																				<>
																					{notif}
																					<br />
																				</>
																			))}
																	</>
																}
															>
																<WarningTwoTone twoToneColor='red' />
															</Tooltip>
														</Space>
													)
												}
											>
												<Card
													type='inner'
													size='small'
													style={{ marginBottom: 16 }}
												>
													<FilmOverview hasError={film.error} />
												</Card>
												<Collapse defaultActiveKey={[]} size='small' ghost>
													{film.releases.map((release, i) => (
														// RELEASES
														<Collapse.Panel
															header={
																<>
																	<div
																		style={{
																			display: 'flex',
																			alignItems: 'center',
																		}}
																	>
																		<div style={{ flexGrow: 1 }}>
																			{film.filmTitle} {release.type}
																		</div>
																		<div style={{ flexGrow: 0 }}>
																			<Space>
																				{/* 																				{release.dcps.filter(
																					(d) => d.dcpInCenter == true
																				).length === release.dcps.length ? (
																					<Tag color='success'>
																						{
																							release.dcps.filter(
																								(d) => d.dcpInCenter === true
																							).length
																						}{' '}
																						/ {release.dcps.length} CTIDs in
																						center
																					</Tag>
																				) : (
																					<Tag color='error'>
																						{
																							release.dcps.filter(
																								(d) => d.dcpInCenter === true
																							).length
																						}{' '}
																						/ {release.dcps.length} CTIDs in
																						center
																					</Tag>
																				)}
 */}{' '}
																			</Space>
																		</div>
																	</div>
																</>
															}
															key={i}
															extra={
																<Space>
																	{film.error && faker.datatype.boolean() ? (
																		faker.helpers
																			.arrayElements(
																				[
																					'Missing DCP (center)',
																					'Missing KDM (center)',
																					'OV/VF Missing',
																					'Intermission Time missing',
																					'Credit offset missing',
																				],
																				{ min: 1, max: 3 }
																			)
																			.map((notif) => (
																				<Tag color='error'>
																					{notif}
																					<br />
																				</Tag>
																			))
																	) : (
																		<CheckCircleTwoTone
																			twoToneColor={'green'}
																		/>
																	)}
																	<Dropdown menu={{ items: releaseMenuItems }}>
																		<MenuOutlined
																			onClick={(event) => {
																				event.stopPropagation();
																			}}
																		/>
																	</Dropdown>
																</Space>
															}
														>
															<Collapse
																defaultActiveKey={[]}
																size='small'
																ghost
															>
																{release.dcps.map((dcp, i) => (
																	// DCPs
																	<Collapse.Panel
																		header={
																			<div
																				style={{
																					display: 'flex',
																					alignItems: 'center',
																				}}
																			>
																				<Typography.Text
																					code
																					style={{ flexGrow: 1 }}
																				>
																					{dcp.title}
																				</Typography.Text>
																				<div style={{ flexGrow: 0 }}>
																					<Space>
																						<Tag
																							color={
																								dcp.dcpInCenter
																									? 'success'
																									: 'error'
																							}
																						>
																							{dcp.dcpInCenter
																								? 'DCP in centers'
																								: 'DCP missing in centers'}
																						</Tag>
																					</Space>
																				</div>
																			</div>
																		}
																		key={i}
																	></Collapse.Panel>
																))}
															</Collapse>
														</Collapse.Panel>
													))}
												</Collapse>
											</Collapse.Panel>
										);
									})}
								</Collapse>
							</Card>
						</section>
					</Content>
				</Layout>
			</Layout>
		</Layout>
	);
}

export default App;
