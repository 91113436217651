import {
	ContainerTwoTone,
	HourglassTwoTone,
	LockTwoTone,
	PlayCircleTwoTone,
} from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { useMemo } from 'react';

interface FilmOverviewProps {
	hasError: boolean;
}
export default function FilmOverview(props: FilmOverviewProps) {
	const statuses = useMemo(() => {
		const dcpStatus = (hasError: boolean) => {
			if (hasError) {
				return (
					<Tooltip title='DCP missing in some centers.'>
						<Button
							type='text'
							icon={<PlayCircleTwoTone twoToneColor={'red'} shape='circle' />}
						/>
					</Tooltip>
				);
			}
			return (
				<Tooltip title='DCP resent in all centers'>
					<Button
						type='text'
						icon={<PlayCircleTwoTone twoToneColor={'green'} shape='circle' />}
					/>
				</Tooltip>
			);
		};
		const kdmStatus = (hasError: boolean) => {
			if (hasError) {
				return (
					<Tooltip title='KDM missing in some centers'>
						<Button
							type='text'
							icon={<LockTwoTone twoToneColor={'red'} shape='circle' />}
						/>
					</Tooltip>
				);
			}
			return (
				<Tooltip title='KDM present in all centers'>
					<Button
						type='text'
						icon={<LockTwoTone twoToneColor={'green'} shape='circle' />}
					/>
				</Tooltip>
			);
		};
		const intermissionStatus = (hasError: boolean) => {
			if (hasError) {
				return (
					<Tooltip title='Intermission time missing for some releases'>
						<Button
							type='text'
							icon={<HourglassTwoTone twoToneColor={'red'} />}
						/>
					</Tooltip>
				);
			}
			return (
				<Tooltip title='Intermission time present for all releases'>
					<Button
						type='text'
						icon={<HourglassTwoTone twoToneColor={'green'} />}
					/>
				</Tooltip>
			);
		};

		const creditOffsetStatus = (hasError: boolean) => {
			if (hasError) {
				return (
					<Tooltip title='Credit offset missing for some releases'>
						<Button
							type='text'
							icon={<ContainerTwoTone twoToneColor={'red'} />}
						/>
					</Tooltip>
				);
			}
			return (
				<Tooltip title='Credit offset present for all releases'>
					<Button
						type='text'
						icon={<ContainerTwoTone twoToneColor={'green'} />}
					/>
				</Tooltip>
			);
		};

		const generateStatus = (hasError: boolean) => {
			if (props.hasError) {
				return (
					<>
						{dcpStatus(faker.datatype.boolean({ probability: 0.3 }))}
						{kdmStatus(faker.datatype.boolean({ probability: 0.3 }))}
						{intermissionStatus(faker.datatype.boolean({ probability: 0.3 }))}
						{creditOffsetStatus(faker.datatype.boolean({ probability: 0.3 }))}
					</>
				);
			}
			return [
				dcpStatus(false),
				kdmStatus(false),
				intermissionStatus(false),
				creditOffsetStatus(false),
			];
		};

		return [0, 1, 2, 3, 4, 5, 6, 7, 8].map(() =>
			generateStatus(props.hasError)
		);
	}, []);

	return (
		<Row justify={'space-between'}>
			{['Thu.', 'Fri.', 'Sat.', 'Sun.', 'Mon.', 'Tue.', 'Wed.'].map(
				(day, i) => (
					<Col>
						<div>
							<Typography.Text strong>{day}</Typography.Text>
						</div>
						<Space direction='vertical' size={1}>
							{statuses[i]}
						</Space>
					</Col>
				)
			)}
		</Row>
	);
}
