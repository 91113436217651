import {
	CheckCircleOutlined,
	DeleteOutlined,
	InfoCircleOutlined,
	NotificationOutlined,
} from '@ant-design/icons';
import { faker } from '@faker-js/faker';
import {
	Badge,
	Button,
	Card,
	Drawer,
	Space,
	Timeline,
	Typography,
	notification,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { centers } from '../data';
import { createDcp, releases } from './createFilm';

const filmListRaw = [
	'The Shawshank Redemption',
	'The Godfather',
	'Pulp Fiction',
	'The Dark Knight',
	'Fight Club',
	'Goodfellas',
	'Inception',
	'The Matrix',
	"Schindler's List",
	'The Lord of the Rings: The Fellowship of the Ring',
	'Forrest Gump',
	'The Avengers',
	'Interstellar',
	'The Silence of the Lambs',
	'The Lion King',
	'Star Wars: Episode IV - A New Hope',
	'Gladiator',
	'Jurassic Park',
	'The Departed',
	'Titanic',
];
type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface notification {
	type: NotificationType;
	message: string;
	description: string;
	timestamp: dayjs.Dayjs;
	placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
}

export const fakeDCP = () => {
	return `${
		createDcp({
			audio: '51',
			filmTitle: faker.helpers.arrayElement(filmListRaw),
			release: faker.helpers.arrayElement(releases),
		}).title
	}`;
};

export const fakeRelease = () => {
	return `${faker.helpers.arrayElement(
		filmListRaw
	)} ${faker.helpers.arrayElement(releases)}`;
};

const notifications = () =>
	[
		{
			type: 'info',
			message: 'CTID assigned',
			description: `${faker.person
				.firstName()
				.slice(0, 1)}. ${faker.person.lastName()} assigned a CTID to 
            ${fakeRelease()}`,
			timestamp: dayjs(new Date()),
			placement: 'bottomRight',
		},
		{
			type: 'info',
			message: 'Credit offset added',
			description: `${faker.person
				.firstName()
				.slice(
					0,
					1
				)}. ${faker.person.lastName()} added credit offset to ${fakeDCP()}`,
			timestamp: dayjs(new Date()),
			placement: 'bottomRight',
		},
		{
			type: 'info',
			message: 'Intermission time added',
			description: `${faker.person
				.firstName()
				.slice(
					0,
					1
				)}. ${faker.person.lastName()} added intermission time to ${fakeDCP()}`,
			timestamp: dayjs(new Date()),
			placement: 'bottomRight',
		},
		{
			type: 'success',
			message: 'KDM imported in center',
			description: `KDM for ${fakeDCP()} has been ingested in center ${faker.helpers.arrayElement(
				centers
			)}`,
			timestamp: dayjs(new Date()),
			placement: 'bottomRight',
		},
	] as notification[];

export default function FakeNotif() {
	const [api, contextHolder] = notification.useNotification();
	const [notificationList, setNotificationList] = useState<notification[]>([]);
	const [open, setOpen] = useState(false);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	const openNotificationWithIcon = ({
		type,
		message,
		description,
		timestamp,
		placement,
	}: {
		type: NotificationType;
		message: string;
		description: string;
		timestamp: dayjs.Dayjs;
		placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
	}) => {
		api[type]({
			description,
			message,
			placement,
		});
	};

	useEffect(() => {
		const interval = setInterval(() => {
			const notification = faker.helpers.arrayElement(notifications());
			/* 			openNotificationWithIcon({
				...notification,
			});
 */ setNotificationList((prev) => [...prev, notification]);
		}, 30000);

		return () => clearInterval(interval);
	}, []);

	return (
		<>
			{contextHolder}
			<Badge count={notificationList.length} size='small'>
				<Button
					shape='circle'
					icon={<NotificationOutlined />}
					onClick={() => showDrawer()}
				/>
			</Badge>
			<Drawer
				title='Notifications'
				placement='right'
				onClose={onClose}
				open={open}
				destroyOnClose
				extra={
					<Space>
						<Button
							type='default'
							size='small'
							icon={<DeleteOutlined />}
							onClick={() => {
								setNotificationList([]);
								onClose();
							}}
						>
							Dismiss all
						</Button>
					</Space>
				}
			>
				<Timeline
					items={notificationList.map((notification, index) => ({
						children: (
							<Card style={{ marginBottom: 16 }}>
								<Card.Meta
									title={
										<>
											<Typography.Text>{notification.message}</Typography.Text>
											{' • '}
											<Typography.Text disabled>
												{notification.timestamp.format('DD.MM.YY HH:mm')}
											</Typography.Text>
										</>
									}
									description={notification.description}
									avatar={
										notification.type === 'success' ? (
											<CheckCircleOutlined />
										) : (
											<InfoCircleOutlined />
										)
									}
								/>
							</Card>
						),
					}))}
				/>
			</Drawer>
		</>
	);
}
