import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';

interface createFilmProps {
	filmTitle: string;
}
export const distributors = [
	'20th Century Studios',
	'Constantin Film',
	'Sony Pictures Releasing',
	'Filmdelights',
	'Kinowelt',
	'Tobis',
	'Sascha-Film',
	'Senator Film',
	'Stadtkino',
	'Universal Pictures',
	'Universum Film',
	'The Walt Disney Company',
	'RKO Radio Pictures GmbH',
	'Warner Bros.',
	'Wild Bunch',
];

export const releases = [
	'2D DE',
	'2D DE FR',
	'2D EN',
	'2D EN DE',
	'2D FR',
	'3D DE',
	'3D DE FR',
	'3D EN',
	'3D EN DE',
	'3D FR',
];

interface createReleaseProps {
	release: string;
	filmTitle: string;
}
export interface release {
	type: string;
	dcps: dcp[];
}
export function createRelease(props: createReleaseProps) {
	return {
		type: props.release,
		dcps: ['51', '71'].map((audio) =>
			createDcp({
				filmTitle: props.filmTitle,
				release: props.release,
				audio: audio,
			})
		),
	} as release;
}

interface createDcpProps {
	audio: string;
	filmTitle: string;
	release: string;
}
export interface dcp {
	title: string;
	dcpInCenter: boolean;
	dcpInServer: boolean;
	kdmInCenter: boolean;
	kdmInServer: boolean;
}
export function createDcp(props: createDcpProps) {
	return {
		title: `${props.filmTitle
			.slice(0, 12)
			.replaceAll(' ', '')}_${props.release.replaceAll(' ', '-')}_${
			props.audio
		}_${dayjs().format('MMDDYYYY')}_VF`,
		dcpInCenter: faker.datatype.boolean({ probability: 0.9 }),
		dcpInServer: faker.datatype.boolean({ probability: 0.9 }),
		kdmInCenter: faker.datatype.boolean({ probability: 0.9 }),
		kdmInServer: faker.datatype.boolean({ probability: 0.9 }),
	} as dcp;
}
export interface film {
	filmTitle: string;
	distributor: string;
	week: number;
	releases: release[];
	error: boolean;
}
export default function createFilm(props: createFilmProps) {
	return {
		filmTitle: props.filmTitle,
		distributor: faker.helpers.arrayElement(distributors),
		week: faker.number.int({ min: 1, max: 10 }),
		releases: faker.helpers
			.arrayElements(releases, { min: 2, max: 5 })
			.map((release) =>
				createRelease({ filmTitle: props.filmTitle, release: release })
			),
		error: faker.datatype.boolean({ probability: 0.45 }),
	} as film;
}
