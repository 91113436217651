import { Skeleton } from 'antd';

export default function ModalSkeleton() {
	return (
		<>
			<Skeleton />
			<Skeleton />
		</>
	);
}
